import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"6","lg":"6","md":"6","sm":"6"}},[_c('h3',[_vm._v("Signature - "+_vm._s(_vm.serviceProjectResponse.idFormatted))])]),_c(VCol,{staticStyle:{"text-align":"right"},attrs:{"cols":"6"}},[_c(VChip,{attrs:{"color":"var(--color__main)","outlined":"","dark":""}},[_vm._v(" "+_vm._s(_vm.serviceProjectResponse.statusDescription)+" ")])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('hr')])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('JobInformation',{attrs:{"id":_vm.serviceProjectResponse.idService,"project":_vm.projectSelected,"showProjects":false,"showJobSummary":true,"showButtonsCreateEditProject":false,"showButtonsCreateEditVersion":false,"showClassification":true,"showDisabledClassification":false}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardText,[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Pool Plan ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"5","md":"12","sm":"12"}},[_c('span',{staticStyle:{"font-size":"13px"}},[_c('strong',[_vm._v("Step 1:")]),_vm._v(" First you must download the pool plan and sign, after you must to send it to the customer for sign")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",staticStyle:{"width":"100% !important","margin-top":"2rem","margin-left":"0px !important"},on:{"click":function($event){return _vm.downloadFile(_vm.URL_ATTACHMENT, _vm.poolPlanFiles[0].id, _vm.poolPlanFiles[0].fileType, _vm.poolPlanFiles[0].fileName)}}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Download PDF Pool Plan ")],1)]}}])},[_c('span',[_vm._v("Download PDF")])])],1),_c(VCol,{staticStyle:{"text-align":"center"},attrs:{"cols":"12","lg":"2","md":"12","sm":"12"}},[_c(VDivider,{attrs:{"vertical":""}})],1),_c(VCol,{attrs:{"cols":"12","lg":"5","md":"12","sm":"12"}},[_c('span',{staticStyle:{"font-size":"13px"}},[_c('strong',[_vm._v("Step 2:")]),_vm._v(" Upload the pool plan signed to complete this process and go to the final plan step")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",staticStyle:{"width":"100% !important","margin-top":"2rem","margin-left":"0px !important"},on:{"click":function($event){return _vm.uploadSignedPoolPlan()}}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-upload-outline")]),_vm._v(" Upload Signed Pool Plan ")],1)]}}])},[_c('span',[_vm._v("Upload Signed Document")])])],1)],1)],1)],1)],1)],1)],1),(_vm.serviceProjectResponse.status > 7)?_c('div',{staticClass:"text-center"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",staticStyle:{"background-color":"var(--color__silver) !important","color":"#ffffff !important"},on:{"click":function($event){return _vm.cancel()}}},on),[_vm._v(" Back ")])]}}],null,false,1409850856)},[_c('span',[_vm._v("Back")])])],1)],1)],1):_vm._e(),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}}),_c('UploadFile',{attrs:{"showUploadFile":_vm.dialogUploadFile.show,"idKey":_vm.dialogUploadFile.idKey,"reload":_vm.dialogUploadFile.reload,"acceptType":_vm.dialogUploadFile.acceptType,"typeAttachment":_vm.dialogUploadFile.typeAttachment,"typeDocument":_vm.dialogUploadFile.typeDocument,"multipleFiles":_vm.dialogUploadFile.multipleFiles,"showMsgConfirmation":_vm.dialogUploadFile.showMsgConfirmation,"msgConfirmation":_vm.dialogUploadFile.msgConfirmation},on:{"methodConfirmAfterUpload":_vm.dialogUploadFile.methodAfterUpload,"update:showUploadFile":function($event){return _vm.$set(_vm.dialogUploadFile, "show", $event)},"update:show-upload-file":function($event){return _vm.$set(_vm.dialogUploadFile, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }